<template>
  <div class="container en_payment_Handler">
    <div class="en_payment_Hanlder_container" v-if="isCallbackSuccess">
      <div class="handler_logo">
        <img src="@/assets/icons/success@2x.svg" alt="success_logo" v-if="isSuccess" />
        <img src="@/assets/icons/error@2x.svg" alt="failre_logo" v-else />
      </div>
      <div class="success_response" v-if="isSuccess">
        <h2>{{ $t("Payment Successful") }}</h2>
        <p>{{ $t("You can start enjoying Noor Play.") }}</p>
        <button @click="login">{{ $t("Login") }}</button>
      </div>
      <div class="failure_response" v-else>
        <div v-if="pendingStatus">
          <h2>{{ "Payment Pending" }}</h2>
          <p>{{ "Please try after sometime" }}</p>
        </div>
        <div v-else>
          <h2>{{ $t("Payment Failed") }}</h2>
          <p>{{ $t("An error occured while processing your payment.") }}</p>
          <!-- <p>{{ notCustomer }} <span @click="login"> {{ $t('Click here') }} </span></p> -->
        </div>
      </div>
    </div>
    <div v-else>
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { mapMutations } from "vuex";
import { eventBus } from "@/eventBus.js";

export default {
  data() {
    return {
      queryParams: null,
      isSuccess: false,
      isCallbackSuccess: false,
      pendingStatus: false,
      routename: null,
    };
  },

  watch: {
    isSuccess(val) {
      if(val) {
        setTimeout(() => {
          this.login();
        },4000)
      }
    }
  },

  computed: {
    notCustomer() {
      if (this.routename === "stc-bahrain") {
        return this.$t("Not a STC customer?");
      } else if (this.routename === "ooredoo-oman" || this.routename === "ooredoo-qatar" || this.routename === "ooredoo-palestine") {
        return this.$t("Not a OOREDOO customer?");
      } else {
        return this.$t("Not a ZAIN customer?");
      }
    },
  },
  created() {
    this.routename = this.$router.currentRoute.params.id;

    const routeParamTemp = this.$route.query.error;

    if (routeParamTemp == "PURCHASE_REQUEST_NOT_VALID") {
      this.$router.go(-1);
      return;
    }

    this.setupLocale();
  },
  methods: {
    ...mapMutations(["setRtl"]),
    setupLocale() {
      this.language_type = this.$route.params && this.$route.params.lang ? this.$route.params.lang : "";

      if (this.language_type == "en") {
        this.$i18n.locale = "eng";
        this.setRtl(false);
      } else {
        this.$i18n.locale = "ara";
        this.setRtl(true);
      }

      localStorage.setItem("setDisplayLanguageCode", this.$i18n.locale);
      eventBus.$emit("check-local-storage", this.$i18n.locale);
      eventBus.$emit("check-for-series-route", this.$i18n.locale);
      eventBus.$emit("tc-pp", this.$i18n.locale);

      this.paymentHandler();
    },

    getPaymentIdFromTrackingId(trackingId) {
      let PaymentId = "";
      let TrackingData = trackingId.split("_");
      console.log(TrackingData.length);
      let PaymentIdDataString = TrackingData[TrackingData.length - 1];
      let PaymentIdData = PaymentIdDataString.split("-");
      console.log(PaymentIdData.length);
      PaymentId = PaymentIdData[PaymentIdData.length - 2];
      return PaymentId;
    },

    paymentHandler() {
      let data = {};
      data = localStorage.getItem("TelcoPlan");
      if (data) {
        data = JSON.parse(data);
      }

      this.handleReferenceid();
    },

    handleReferenceid() {
      let payload = {};
      console.log("current Route query params", this.$router.currentRoute);
      if (Object.keys(this.$router.currentRoute.query).length !== 0) {
        this.queryParams = this.$router.currentRoute.query;
        if (Object.keys(this.queryParams).length > 0 && this.queryParams.hasOwnProperty("referenceid")) {
          payload.referenceid = this.queryParams.referenceid;
          this.paymentCallback(payload);
        } else if (Object.keys(this.queryParams).length > 0 && this.queryParams.hasOwnProperty("correlatorId")) {
          let correlatorId = this.queryParams.correlatorId;
          payload.referenceid = this.getPaymentIdFromTrackingId(correlatorId);
          this.paymentCallback(payload);
        } else if (
          Object.keys(this.queryParams).length > 0 &&
          this.queryParams.message &&
          this.queryParams.message === "OK"
        ) {
          this.isCallbackSuccess = true;
          this.isSuccess = true;

          // Get Plan details
          let planData = {};
          planData = localStorage.getItem("TelcoPlan");
          if (planData) {
            planData = JSON.parse(planData);
          }

          console.log("TELCO PLAN DETAILS -- ", planData);

          const data = { ...planData };

          this.telco_trial_success(data);
        } else if (
          Object.keys(this.queryParams).length > 0 &&
          ((this.queryParams.message && this.queryParams.message !== "OK") || this.queryParams.hasOwnProperty("error"))
        ) {
          if (this.queryParams.status === "403") {
            const data = {};
            this.telco_trial_cancelled(data);
          } else {
            // Get Plan details
            let planData = {};
            planData = localStorage.getItem("TelcoPlan");
            if (planData) {
              planData = JSON.parse(planData);
            }

            console.log("TELCO PLAN DETAILS -- ", planData);

            const data = { ...planData };
            this.telco_trial_failed(data);
          }
          this.isCallbackSuccess = true;
          this.isSuccess = false;
        }
      } else {
        payload.referenceid = this.$route.params.referenceid;
        this.paymentCallback(payload);
      }
    },

    paymentCallback(payload) {
      let data = {};
      data = localStorage.getItem("TelcoPlan");
      if (data) {
        data = JSON.parse(data);
      }

      this.isCallbackSuccess = false;
      this.fetchPaymentDetail(payload)
        .then((response) => {
          console.log("payment detail response", response);
          if (!response.reason) {
            if (response.transactionstatus !== "SUCCESS") {
              if (response.transactionstatus == "PENDING") {
                this.pendingStatus = true;
              }
              this.isSuccess = false;
              this.isCallbackSuccess = true;
            } else {
              this.isCallbackSuccess = true;
              this.isSuccess = true;

              // Get Plan details
              let planData = {};
              planData = localStorage.getItem("TelcoPlan");
              if (planData) {
                planData = JSON.parse(planData);
              }

              console.log("TELCO PLAN DETAILS -- ", planData);

              const data = { ...planData };

              this.telco_trial_success(data);
            }
          } else {
            this.isCallbackSuccess = true;
            this.isSuccess = false;

            // Get Plan details
            let planData = {};
            planData = localStorage.getItem("TelcoPlan");
            if (planData) {
              planData = JSON.parse(planData);
            }

            console.log("TELCO PLAN DETAILS -- ", planData);

            const paramData = { ...planData };

            paramData.error_code = this.$route.query && this.$route.query.status ? this.$route.query.status : "";
            paramData.error_message = this.$route.query && this.$route.query.error ? this.$route.query.error : "";

            this.telco_trial_failed(paramData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchPaymentDetail(payload) {
      return store.dispatch("telcoPaymentDetail", payload);
    },

    login() {
      // this.$emit("openAuth", true);
      this.$router.push("/");

      //open lookup popup.
      let payload = {
        state: true,
        formType: "lookup",
      };
      eventBus.$emit("authPopup", payload);
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [googleAnalytics],
  beforeDestroy() {
    localStorage.removeItem("TelcoPlan");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.en_payment_Handler {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71vh;
}
.handler_logo {
  text-align: center;
}
.success_response {
  padding: 10px 0;
}
.success_response {
  font-family: $font-helvetica-medium;
  color: #ffffff;
  text-align: center;
  h2 {
    font-weight: 400;
    margin: 10px 0;
  }
  p {
    font-size: 0.7rem;
    font-weight: 400;
    margin: 10px 0;
  }
  button {
    border: none;
    border-radius: 5px;
    background: #cd154e;
    color: #ffffff;
    font-weight: 400;
    font-size: 0.7rem;
    padding: 5px 20px;
    cursor: pointer;
  }
}
.failure_response {
  font-family: $font-helvetica-medium;
  color: #ffffff;
  text-align: center;
  h2 {
    font-weight: 400;
    margin: 10px 0;
  }
  p {
    margin: 5px 0;
    font-size: 0.7rem;
    font-weight: 400;
    margin: 10px 0;
    opacity: 0.9;
  }
  span {
    letter-spacing: 0px;
    color: #aa1a62;
    cursor: pointer;
  }
}
</style>
